/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
      	setTimeout(function() {
    		$('#message').fadeOut('fast');
		}, 5000);
        //functions
        imagesLoaded( 'body', function() {
            $("#preloader").fadeOut("fast");
            $("body").removeClass("preload");
        });

		$(function() {
            var header = $(".logo-nav");
            $(window).scroll(function() {
                var scroll = $(window).scrollTop();
                if (scroll >= 300) {
                    header.addClass("fixed");
                } else {
                    header.removeClass("fixed");
                }
            });
        });

        $(document).foundation(); // Foundation JavaScript

        WebFontConfig = {
          google: { families: [ 'Raleway:400,300,600,700,600italic,400italic,300italic,900,900italic,700italic,800,800italic:latin,latin-ext', 'Source+Sans+Pro:400,300,300italic,400italic,700,700italic,900,900italic:latin,latin-ext' ] }
        };
        (function() {
          var wf = document.createElement('script');
          wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
          wf.type = 'text/javascript';
          wf.async = 'true';
          var s = document.getElementsByTagName('script')[0];
          s.parentNode.insertBefore(wf, s);
        })();

		if ($('#map').length){
		  var width = $(window).width();

		  if (width <= 1024) {
			is_mobile = false;
		  } else {
			is_mobile = true;
		  }

		  // Basic options for a simple Google Map
		  // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
		  var mapOptions = {
			// How zoomed in you want the map to start at (always required)
			zoom: 14,
			draggable: is_mobile,
			scrollwheel: false,
			// The latitude and longitude to center the map (always required)
			center: new google.maps.LatLng(52.224016, 21.012254), // tu podaj adres

			// How you would like to style the map.
			// This is where you would paste any style found on Snazzy Maps.
			styles: [{
			  "featureType": "water",
			  "elementType": "geometry",
			  "stylers": [{
				"color": "#e9e9e9"
			  }, {
				"lightness": 17
			  }]
			}, {
			  "featureType": "landscape",
			  "elementType": "geometry",
			  "stylers": [{
				"color": "#f5f5f5"
			  }, {
				"lightness": 20
			  }]
			}, {
			  "featureType": "road.highway",
			  "elementType": "geometry.fill",
			  "stylers": [{
				"color": "#ffffff"
			  }, {
				"lightness": 17
			  }]
			}, {
			  "featureType": "road.highway",
			  "elementType": "geometry.stroke",
			  "stylers": [{
				"color": "#ffffff"
			  }, {
				"lightness": 29
			  }, {
				"weight": 0.2
			  }]
			}, {
			  "featureType": "road.arterial",
			  "elementType": "geometry",
			  "stylers": [{
				"color": "#ffffff"
			  }, {
				"lightness": 18
			  }]
			}, {
			  "featureType": "road.local",
			  "elementType": "geometry",
			  "stylers": [{
				"color": "#ffffff"
			  }, {
				"lightness": 16
			  }]
			}, {
			  "featureType": "poi",
			  "elementType": "geometry",
			  "stylers": [{
				"color": "#f5f5f5"
			  }, {
				"lightness": 21
			  }]
			}, {
			  "featureType": "poi.park",
			  "elementType": "geometry",
			  "stylers": [{
				"color": "#dedede"
			  }, {
				"lightness": 21
			  }]
			}, {
			  "elementType": "labels.text.stroke",
			  "stylers": [{
				"visibility": "on"
			  }, {
				"color": "#ffffff"
			  }, {
				"lightness": 16
			  }]
			}, {
			  "elementType": "labels.text.fill",
			  "stylers": [{
				"saturation": 36
			  }, {
				"color": "#333333"
			  }, {
				"lightness": 40
			  }]
			}, {
			  "elementType": "labels.icon",
			  "stylers": [{
				"visibility": "off"
			  }]
			}, {
			  "featureType": "transit",
			  "elementType": "geometry",
			  "stylers": [{
				"color": "#f2f2f2"
			  }, {
				"lightness": 19
			  }]
			}, {
			  "featureType": "administrative",
			  "elementType": "geometry.fill",
			  "stylers": [{
				"color": "#fefefe"
			  }, {
				"lightness": 20
			  }]
			}, {
			  "featureType": "administrative",
			  "elementType": "geometry.stroke",
			  "stylers": [{
				"color": "#fefefe"
			  }, {
				"lightness": 17
			  }, {
				"weight": 1.2
			  }]
			}]
		  };

		  // Get the HTML DOM element that will contain your map
		  // We are using a div with id="map" seen below in the <body>
		  var mapElement = document.getElementById('map');

		  // Create the Google Map using our element and options defined above
		  var map = new google.maps.Map(mapElement, mapOptions);

		  // Let's also add a marker while we're at it
		  var marker = new google.maps.Marker({
			position: new google.maps.LatLng(52.224016, 21.012254),
			map: map,
			icon: template_url + "/dist/images/mappoint.png",
			title: 'Chillout Hostel ul. Poznańska 7 m. 4 00-680 Warsaw, Poland'
		  });
	  	}

        var wow = new WOW();
        wow.init();

        var $container = $('.offer-list').imagesLoaded( function() {
          // initialize Packery after all images have loaded
          $container.packery({
            itemSelector: '.item',
            gutter: 0
          });
        });

        $(".mobilemenu").hide();
        $(".menu-open").click(function() {
          if ( $(".mobilemenu-btn").hasClass('open') ){
            $("body").removeClass("preload");
            $(".mobilemenu").removeClass("slideInUp").removeClass("animated").addClass("slideOutUp").addClass("animated").show();
          } else {
            $("body").addClass("preload");
            $(".mobilemenu").removeClass("slideOutUp").removeClass("animated").addClass("slideInUp").addClass("animated").show();
          }

          $(".mobilemenu-btn").toggleClass('open');
        });

        $('[data-tabs]').on('change.zf.tabs', function() {
            imagesLoaded( '.photos-slider', function() {
                flickity_var.flickity( 'destroy' );
                flickity_var = $('.photos-slider').flickity({
                  cellAlign: 'center',
                  contain: true,
                  wrapAround: true,
                  prevNextButtons: true,
                  pageDots: false,
                  autoPlay: true
                });
            });
        });

	  	$('.all, .image-link').magnificPopup(
			{
				type:'image',
				gallery:{
    				enabled:true
  				}
			}
		);

        $(".arrow-down").click(function() {
            $('html, body').animate({
                scrollTop: $(".first").offset().top
            }, 500);
            return false;
        });

        $(".arrow-up").click(function() {
            $('html, body').animate({
                scrollTop: $("#header").offset().top
            }, 500);
            return false;
        });

        $(".app").click(function() {
            $('html, body').animate({
                scrollTop: $(".app-section").offset().top
            }, 500);
            return false;
        });

        imagesLoaded( '.rooms-slider', function() {
            $('.rooms-slider').flickity({
              cellAlign: 'center',
              contain: true,
              wrapAround: true,
              prevNextButtons: true,
              pageDots: false,
              autoPlay: true
            });
        });

        imagesLoaded( '.photos-slider', function() {
            flickity_var = $('.photos-slider').flickity({
              cellAlign: 'center',
              contain: true,
              wrapAround: true,
              prevNextButtons: true,
              pageDots: false,
              autoPlay: true
            });
        });

		/*
		* Datepicker ładny :)
		*/
		// tłumacznie pl
		if( lang == 'pl' ){
			$.extend( $.fn.pickadate.defaults, {
			    monthsFull: [ 'styczeń', 'luty', 'marzec', 'kwiecień', 'maj', 'czerwiec', 'lipiec', 'sierpień', 'wrzesień', 'październik', 'listopad', 'grudzień' ],
			    monthsShort: [ 'sty', 'lut', 'mar', 'kwi', 'maj', 'cze', 'lip', 'sie', 'wrz', 'paź', 'lis', 'gru' ],
			    weekdaysFull: [ 'niedziela', 'poniedziałek', 'wtorek', 'środa', 'czwartek', 'piątek', 'sobota' ],
			    weekdaysShort: [ 'niedz.', 'pn.', 'wt.', 'śr.', 'cz.', 'pt.', 'sob.' ],
			    today: 'Dzisiaj',
			    clear: 'Usuń',
			    close: 'Zamknij',
			    firstDay: 1,
			    format: 'dd mm yyyy',
			    formatSubmit: 'yyyy-mm-dd'
			});

			$.extend( $.fn.pickatime.defaults, {
			    clear: 'usunąć'
			});
		} else {
			$.extend( $.fn.pickadate.defaults, {
			    format: 'dd mm yyyy',
			    formatSubmit: 'yyyy-mm-dd'
			});
		}

		var $input = $('.datepicker').pickadate({
			container: '#checkout',
            editable: true,
		});

        var picker = $input.pickadate('picker');

        $( '.datepicker' ).click( function() {
            picker.open();
        });

		/*
		* Gdy ALM się kończy - ukrywamy button
		 */
		$.fn.almDone = function(){
		   $('#load-more').hide();
		};

		if(window.location.hash) {
		    // We have an anchor link in our URL
		    var f6dl_url = window.location.hash;
		    var f6dl_hash = f6dl_url.substring(f6dl_url.indexOf("#")+1);
		    if($('#'+f6dl_hash).length) {
		        // Insert id of the <ul> that holds the links to the tabs
		        var f6dl_ulid = 'nasze-pokoje';
				setTimeout(function() {
			    	window.scrollTo(0, 0);
			    	var off = jQuery(location.hash).offset().top - 60;
			    	jQuery('html, body').animate({
			            scrollTop: off
			        }, 1000);
			  	}, 1);
		        $('#' + f6dl_ulid).find('a').each(function(e) {
		            var f6dl_href = $(this).attr('href');
		            if(f6dl_href === "#"+f6dl_hash) {
		                // Click the link to activate the tab
		                $(this).click();
		            }
		        });

		    }
		}

		$('[data-tabs]').on('change.zf.tabs', function() {
		  id = $( '#nasze-pokoje li.is-active a' ).attr( 'href' );
		  window.history.pushState("", "", id );
		});

		$(".chosen-select").chosen({no_results_text: "Oops, nothing found!"});
        $(".chosen-select").css({
            "display":"inline-block",
            "position":"absolute",
        });

		// Sygnatura w formularzu
		// doc: https://github.com/szimek/signature_pad

		var wrapper = document.getElementById("signature-pad"),
		    clearButton = wrapper.querySelector("[data-action=clear]"),
		    saveButton = wrapper.querySelector("[data-action=save]"),
		    canvas = wrapper.querySelector("canvas"),
		    signaturePad;

		// Adjust canvas coordinate space taking into account pixel ratio,
		// to make it look crisp on mobile devices.
		// This also causes canvas to be cleared.
		function resizeCanvas() {
		    // When zoomed out to less than 100%, for some very strange reason,
		    // some browsers report devicePixelRatio as less than 1
		    // and only part of the canvas is cleared then.
		    var ratio =  Math.max(window.devicePixelRatio || 1, 1);
		    canvas.width = canvas.offsetWidth * ratio;
		    canvas.height = canvas.offsetHeight * ratio;
		    canvas.getContext("2d").scale(ratio, ratio);
		}

		window.onresize = resizeCanvas;
		resizeCanvas();

		signaturePad = new SignaturePad(canvas);

		clearButton.addEventListener("click", function (event) {
		    signaturePad.clear();
		});

		/*saveButton.addEventListener("click", function (event) {
		    if (signaturePad.isEmpty()) {
		        alert("Please provide signature first.");
		    } else {
		        window.open(signaturePad.toDataURL());
		    }
		});*/

		$( '#form_submit' ).submit( function( e ) {
			if ( ! signaturePad.isEmpty()) {
				var sig = signaturePad.toDataURL();
				$( '#signature_value' ).val( sig );
			} else {
				document.getElementById("signature-empty").innerHTML="Please, enter your signature.";
				return false;
			}
			console.log( sig );
		});

		$(function() {
		  $.fn.almComplete = function(alm){
		    //heights = $('.news_row').foundation('getHeights');
			new Foundation.Equalizer($(".news_row")).applyHeight();
			//console.log( heights );
		  };
		});

		$('.wpcf7-form').submit( function(){
		  setTimeout(function() {
			  new Foundation.Equalizer($("#grupy_equalizer")).applyHeight();
		  }, 2000 );
		});

        if ($('#map').length){
          var width = $(window).width();

          if (width <= 1024) {
            is_mobile = false;
          } else {
            is_mobile = true;
          }

          // Basic options for a simple Google Map
          // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
          var mapOptions = {
            // How zoomed in you want the map to start at (always required)
            zoom: 14,
            draggable: is_mobile,
            scrollwheel: false,
            // The latitude and longitude to center the map (always required)
            center: new google.maps.LatLng(52.224016, 21.012254), // tu podaj adres

            // How you would like to style the map.
            // This is where you would paste any style found on Snazzy Maps.
            styles: [{
              "featureType": "water",
              "elementType": "geometry",
              "stylers": [{
                "color": "#e9e9e9"
              }, {
                "lightness": 17
              }]
            }, {
              "featureType": "landscape",
              "elementType": "geometry",
              "stylers": [{
                "color": "#f5f5f5"
              }, {
                "lightness": 20
              }]
            }, {
              "featureType": "road.highway",
              "elementType": "geometry.fill",
              "stylers": [{
                "color": "#ffffff"
              }, {
                "lightness": 17
              }]
            }, {
              "featureType": "road.highway",
              "elementType": "geometry.stroke",
              "stylers": [{
                "color": "#ffffff"
              }, {
                "lightness": 29
              }, {
                "weight": 0.2
              }]
            }, {
              "featureType": "road.arterial",
              "elementType": "geometry",
              "stylers": [{
                "color": "#ffffff"
              }, {
                "lightness": 18
              }]
            }, {
              "featureType": "road.local",
              "elementType": "geometry",
              "stylers": [{
                "color": "#ffffff"
              }, {
                "lightness": 16
              }]
            }, {
              "featureType": "poi",
              "elementType": "geometry",
              "stylers": [{
                "color": "#f5f5f5"
              }, {
                "lightness": 21
              }]
            }, {
              "featureType": "poi.park",
              "elementType": "geometry",
              "stylers": [{
                "color": "#dedede"
              }, {
                "lightness": 21
              }]
            }, {
              "elementType": "labels.text.stroke",
              "stylers": [{
                "visibility": "on"
              }, {
                "color": "#ffffff"
              }, {
                "lightness": 16
              }]
            }, {
              "elementType": "labels.text.fill",
              "stylers": [{
                "saturation": 36
              }, {
                "color": "#333333"
              }, {
                "lightness": 40
              }]
            }, {
              "elementType": "labels.icon",
              "stylers": [{
                "visibility": "off"
              }]
            }, {
              "featureType": "transit",
              "elementType": "geometry",
              "stylers": [{
                "color": "#f2f2f2"
              }, {
                "lightness": 19
              }]
            }, {
              "featureType": "administrative",
              "elementType": "geometry.fill",
              "stylers": [{
                "color": "#fefefe"
              }, {
                "lightness": 20
              }]
            }, {
              "featureType": "administrative",
              "elementType": "geometry.stroke",
              "stylers": [{
                "color": "#fefefe"
              }, {
                "lightness": 17
              }, {
                "weight": 1.2
              }]
            }]
          };

          // Get the HTML DOM element that will contain your map
          // We are using a div with id="map" seen below in the <body>
          var mapElement = document.getElementById('map');

          // Create the Google Map using our element and options defined above
          var map = new google.maps.Map(mapElement, mapOptions);

          // Let's also add a marker while we're at it

          // TODO: podmienić ścieżkę docelowa do obrazka dla pointera!!
          var marker = new google.maps.Marker({
            position: new google.maps.LatLng(52.224016, 21.012254),
            map: map,
            icon: template_url + "/dist/images/mappoint.png",
            title: 'Chillout Hostel ul. Poznańska 7 m. 4 00-680 Warsaw, Poland'
          });
        }

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

    'kontakt': {
      init: function() {
        // When the window has finished loading create our google map below
        //google.maps.event.addDomListener(window, 'load', init);
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
